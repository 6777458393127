.contact-container{
    max-width: 1400px;
}
.contact-page-wrapper{
    display: flex;
    max-width: 1100px;
    margin: 8rem auto ;
    border-radius: 3rem;
}
.form-container{
    flex: 2;
    background-color: white;

}
.get-intouch-container{
    flex: 1;
    padding: 5rem 2rem;
    background-color: #002766;
}