.new-nav{
    position: absolute;
    right: 0;
    /* top: 0; */
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
p{
  line-height: 31px !important;
}
.container-new{
    padding-right: 15px;
    padding-left: 15px;
    /* max-width: 1500px; */
    position: relative;
  z-index: 5; 
    margin: 0 auto;
}
.navbar>.container{
    max-width: 1450px;
    margin-top: -1rem;
}
.our-approach-p{
    max-width: 800px;
    font-size: 1.125rem;
    margin: 1rem auto;
    color: rgba(0, 0, 0, 0.637);
}
.wakanda-expert-p{
    max-width: 600px;
    margin: 0 auto; 
}
.whyjoin-row{
    display: flex;
    align-items: center;
    margin-left: 6rem;

}
.whyjoin-row>div{
    flex: 1;
}
.mission-row{
    /* max-width: 1200px;
    margin: 0 auto;  */
    padding: 0 6rem;
}
@media (max-width:900px){
  .mission-row{
    padding: 0 2rem;
  }
}
@media (max-width:600px){
  .mission-row{
    padding: 0 1rem;
  }
}
.why-join-top{
    flex: 1;
}
.our-approach-title>span{
    color: var(--color-blue5) !important;
    position: relative;
    display: inline-block;
    padding: 0 5px;
    margin: 0 5px;
}
.job-btn{
    border: none;
    background: linear-gradient(to right, #0c3df4 0%, #02b5ff 100%);;
    color: white;
    margin-top: 1rem;
}
.mission-card{
    flex: 1;
}
.page-wrapper{
    margin-top: 8rem;
}
.page-wrapper-s{
    margin-top: 5rem;
}
.page-wrapper-a{
    margin-top: 8.8rem;
}
.page-color{
    background-color: #E7F1FF;
    background-color: #f2f6fd ;
}
.black-color>.nav-item>.nav-link{
    /* color: rgb(1, 1, 37) !important; */
    font-size: 13px !important;
}
.navbar.style-3.nav-scroll .black-color>.nav-item>.nav-link{ 
    color: white !important;

}
.navbar.style-3.nav-scroll .black-color>.nav-link>.white-logo-w{ 
    display: block !important;
}
.navbar.style-3.nav-scroll .black-color>.nav-link>.blue-logo{ 
    display: none !important;
}
.navbar.style-3 .black-color>.nav-link>.white-logo-w{ 
    /* display: none !important; */
}
.navbar.style-3 .nav-link>.white-logo-w{ 
    /* display: none !important; */
}
/* new */


/*===================================================
 .text_box textarea,
=*/
/*====================================================*/
/*================= Start breadcrumb_area css =================*/
.breadcrumb_area {
    background-image: -moz-linear-gradient(180deg, #5e2ced 0%, #a485fd 100%);
    background-image: -webkit-linear-gradient(180deg, #5e2ced 0%, #a485fd 100%);
    background-image: -ms-linear-gradient(180deg, #5e2ced 0%, #a485fd 100%);
    background-image:  linear-gradient(180deg, #5e2ced 0%, #a485fd 100%);
  background-image: linear-gradient(180deg, #020230 0%, #311b70 100%);
  background-image: linear-gradient(180deg, #041b4d 0%, #0f3ea5 100%);
  background:linear-gradient(to right, #0c3df4 0%, #02b5ff 100%);;

    position: relative;
    z-index: 1;
    padding: 235px 0px 125px;
    overflow: hidden;
  
  }

  .breadcrumb_area::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 60%;
    background-image: url(../public/assets/img/0011.png);
    background-size: 80%;
    background-repeat: repeat;
    background-position: bottom;
    opacity: 0.1;
    -webkit-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(1003%) contrast(103%);
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(1003%) contrast(103%); }
    
    .breadcrumb_area .breadcrumb_shap {
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
    }
    .breadcrumb_area p{
      color: white;
      /* font-size: 1.1rem; */
      font-size: 1.125rem;
    max-width: 680px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 1rem;
  }
  .breadcrumb_area h1{
    font-size: 50px;
    line-height: 20px;
    margin-bottom: 40px;
    font-weight: 700;
    color: white;
  }
  
  @media (max-width:950px) {
    .breadcrumb_area {
      /* height: 45vh; */
    }
    .breadcrumb_area h1 {
      margin-top: 0rem;
      font-size: 2rem;
    }
    .breadcrumb_area p{
      color: white;
      font-size: 0.9rem;
      max-width: 580px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0;
      margin-bottom: 1rem;
    }
    .bubble.b_five{
      opacity: 0.5;
      display: none;
    }
    .bubble.b_two{
      opacity: 0.5;
      display: none;
    }
  
  }
  /*================= End breadcrumb_area css =================*/
  /*================= Start breadcrumb_area_two css =================*/
  .breadcrumb_area_two {
    color:white !important;
    background: #f8f6fe;
    position: relative;
    padding: 235px 0px 125px;
    z-index: 1;
  }
  
  .breadcrumb_area_two .breadcrumb_shap {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    height: 100%;
  }
  


  /* Mission vision new card */

  .app_service_area {
    padding-top: 120px;
    background: #fff;
  }
  
  .app_service_info {
    margin-bottom: -90px;
    position: relative;
    z-index: 2;
    overflow: hidden;
  }
  
  .app_service_item {
    border: 1px solid #f4f4f9;
    background-color: white;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
    box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
    padding: 50px;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    margin-bottom: 30px;
    cursor: pointer;
  }
  
  .app_service_item img {
    font-size: 40px;
    width: 80px;
    height: 80px;
    line-height: 45px;
  }
  .app_service_item p {
    /* font-size: 0.9rem; */
    font-size: 1.125rem;
    color: rgba(0, 0, 0, 0.637);
  }
  
  .app_service_item h5 {
    /* font-size: 1.4rem !important; */
  }
  
  .app_service_item .app_icon.one {
    text-shadow: 0px 14px 30px rgba(44, 130, 237, 0.4);
    color: #2171d4;
  }
  
  .app_service_item .app_icon.two {
    text-shadow: 0px 14px 30px rgba(224, 56, 39, 0.4);
    color: #e03827;
  }
  
  .app_service_item .app_icon.three {
    text-shadow: 0px 14px 30px rgba(94, 44, 237, 0.4);
    color: #5e2ced;
  }
  
  .app_service_item .learn_btn_two {
    color: #222d39;
    font-size: 14px;
  }
  
  .app_service_item .learn_btn_two:before {
    background: #4069eb;
  }
  
  .app_service_item .learn_btn_two:hover {
    color: #4069eb;
  }
  
  .app_service_item .learn_btn_two.c_violet:hover {
    color: #5e2ced;
  }
  
  .app_service_item .learn_btn_two.c_violet:hover:before {
    background: #5e2ced;
  }
  
  .app_service_item:hover {
    -webkit-box-shadow: 0px 20px 40px 0px rgba(12, 0, 46, 0.08);
    box-shadow: 0px 20px 40px 0px rgba(12, 0, 46, 0.08);
  }
  
  .app_service_area_two {
    background: #f9f9fc;
    padding: 120px 0px;
  }
  
  .app_service_area_two .app_service_info {
    margin-bottom: -30px;
  }
  
  /*============== service_details_area css ==============*/
  .service_details_img {
    margin-left: -80px;
  }
  
  .service_details ul {
    padding-left: 35px;
  }
  
  .service_details ul li {
    font: 400 16px "Poppins", sans-serif;
    color: #677294;
    position: relative;
    padding-left: 35px;
    margin-bottom: 13px;
  }
  
  .service_details ul li img {
    position: absolute;
    left: 0;
    top: 5px;
    color: #5e2ced;
  }
  
  .service_details ul li:last-child {
    margin-bottom: 0px;
  }
  
  .service_details_item + .service_details_item {
    margin-top: 200px;
  }
  /* end */


  /* why us part */
  /*============= agency_service_area css =============*/
.why-us-title{
  font-size: 2rem;
  text-align: center;
  margin-bottom: 3rem;
  color: #020230;
}
.agency_service_area {
  padding: 0px 0px 140px;
}
.p_service_item h5 {
  color: #222d39;
  font-weight: 600;
  /* font-size: 17px; */
  line-height: 22px;
  margin-bottom: 15px;
}
.agency_service_item {
  margin-bottom: 30px;
}

.agency_service_item .icon {
  width: auto;
  height: auto;
  display: inline-block;
}

.agency_service_item .icon i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.agency_service_item a {
  font: 400 14px/26px "Poppins", sans-serif;
  color: #222d39;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  -webkit-transition: color 0.3s linear 0s;
  -o-transition: color 0.3s linear 0s;
  transition: color 0.3s linear 0s;
}

.agency_service_item a:before {
  content: "";
  width: 100%;
  height: 1px;
  background: #222d39;
  left: 0;
  bottom: 0;
  position: absolute;
  z-index: -1;
  -webkit-transition: width 0.3s linear 0s;
  -o-transition: width 0.3s linear 0s;
  transition: width 0.3s linear 0s;
}

.agency_service_item p {
  display: inline-block;
  /* font-size: 15px; */
  color: #677294;
  font-size: 1.125rem;
  margin-top: 0;
  margin-bottom: 1rem;
}
.agency_service_item img {
  width: 40px;
  height: 40px;
}
.agency_service_item .icon {
  width: 60px;
  height: 60px;
  margin-bottom: 1rem;
  border-radius: 50%;
  background: #ebf2fe;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 70px;
  margin-right: 30px;
}

.agency_service_item p i {
  vertical-align: middle;
  padding-left: 8px;
  -webkit-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}

.agency_service_item p:hover a {
  color: #5e2ced;
}

.agency_service_item p:hover a:before {
  width: 132%;
  background: #5e2ced;
}

.agency_service_item p:hover i {
  color: #5e2ced;
  padding-left: 12px;
}
/* end */

/* new service deox card design */
.hosting_title h1{
  color: rgb(0, 0, 0);
  font-weight: 500;
  font-size: 1.7rem;
  text-transform: capitalize;
} 
.hosting_title h1 span{
  font-weight: 700;
}
.service-box {
  border-radius: 10px;
  padding: 28px 29px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.service-box .service-icon img {
  width: 40px;
  font-size: 44px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.service-box .read-more-icon .right-icon {
  font-size: 30px !important;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.service-box:before {
  content: "";
  height: 100px;
  width: 100px;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 10%;
  z-index: -1;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.service-box:after {
  content: "";
  height: 0px;
  width: 0px;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 10%;
  z-index: -1;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.service-box:hover .service-icon {
  color: #ffffff;
}
.service-box:hover .service-icon img{
  width: 40px;
}

.service-box:hover .service-title {
  color: #ffffff !important;
}

.service-box:hover .right-icon {
  color: #ffffff;
}

.service-box:hover .service-subtitle {
  color: rgba(255, 255, 255, 0.7) !important;
  font-size: 1.125rem;
}

.service-box:hover:before {
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  border-radius: 0;
}

.service-box:hover:after {
  background: rgba(255, 255, 255, 0.06);
  height: 100px;
  width: 100px;
}

.service-warning .service-icon, .service-warning .right-icon {
  color: #f8636b;
}

.service-warning:before {
  background: rgba(87, 140, 255, 0.05);
}

.service-warning:hover:before {
  background: #f8636b;
  background: #020230;
  background: #310522;
  background: linear-gradient(to right, #0c3df4 0%, #02b5ff 100%);
}
.navbar-brand img{
  padding-top: 7px;
  width: 250px;
}
/* end */


/* new costume nav */
/* .nav-item .nav-link {
  display: inline-block;
  color: #343a40;
  text-decoration: none;
}

.navbar-custom {
  .agency_service_item p {
  padding: 14px 0px;
  width: 100%;
  border-radius: 0px;
  z-index: 999;
  margin-bottom: 0px;
  select-field
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  background-color: transparent;
}

.navbar-custom .logo .logo-dark {
  display: none;
}

.navbar-custom .logo .ligo-light {
  display: inline-block;
} */
/* 
.navbar-custom .navbar-nav li a {
  line-height: 26px;
  color: #8e9cb1; */
  /* font-size: 15px; */
  /* -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background-color: transparent !important;
  padding: 6px 0;
  margin: 0 20px;
}

.navbar-custom .navbar-nav li .nav-link {
  padding-right: 0px;
  padding-left: 0px;
}

.navbar-custom .navbar-nav li.active a,
.navbar-custom .navbar-nav li a:hover,
.navbar-custom .navbar-nav li a:active {
  color: #ffffff !important;
}

.navbar-custom .navbar-toggles {
  padding: 0 !important;
  font-size: 18px;
  background: 0 0;
  border: 1px solid transparent;
  color: #ffffff;
  outline: 0;
}

.navbar-toggler {
  font-size: 24px;
  margin-top: 5px;
  margin-bottom: 0px;
  color: #ffffff;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background-color: transparent;
  border-color: #2558ab;
}

.menu-toggle {
  padding: 4.5px 10px !important;
}

.menu-toggle span {
  line-height: 27px;
} */

/*---NAVBAR STICKY--*/
/* .nav-sticky.navbar-custom {
  margin-top: 0px;
  background-color: #2f0df1;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
  color: #000000 !important;
}

.nav-sticky.navbar-custom .navbar-toggles {
  padding: .25rem .75rem;
  border: 1px solid transparent;
  outline: 0;
}

.nav-sticky.navbar-custom .navbar-nav {
  margin-top: 0px;
}

.nav-sticky.navbar-custom .navbar-nav li a {
  color: #8b99ae !important;
}

.nav-sticky.navbar-custom .navbar-nav li.active a,
.nav-sticky.navbar-custom .navbar-nav li a:hover,
.nav-sticky.navbar-custom .navbar-nav li a:active {
  color: #2558ab !important;
}

.nav-sticky .call-no a {
  color: #ff8057 !important;
}

.nav-sticky .logo .logo-dark {
  display: inline-block !important;
}

.nav-sticky .logo .logo-light {
  display: none !important;
}

.navbar-toggler:focus {
  outline: none;
} */

/*---NAVBAR LIGHT--*/
/* .navbar-light .navbar-nav li a {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-light .navbar-nav li.active a,
.navbar-light .navbar-nav li a:hover,
.navbar-light .navbar-nav li a:active {
  color: #ffffff !important;
} */

/*---Responsive--*/
/* @media (min-width: 200px) and (max-width: 768px) {
  .navbar-custom {
    margin-top: 0px;
    background-color: #2a3ffa !important;
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
    color: #1863ee !important;
  } */
  /* .navbar-custom .navbar-nav {
    margin-top: 0px;
  }
  .navbar-custom .navbar-nav li a {
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    color: #9da9bb !important;
    margin: 0px;
  }
  .navbar-custom .navbar-nav li.active a {
    border-color: transparent;
  }
  .navbar-custom .navbar-nav li.active a,
  .navbar-custom .navbar-nav li a:hover,
  .navbar-custom .navbar-nav li a:active {
    color: #2558ab !important;
  }
  .navbar-custom > .container {
    width: 90%;
  }
  .navbar-custom .logo .logo-dark {
    display: inline-block !important;
  }
  .navbar-custom .logo .logo-light {
    display: none !important;
  }
  .navbar-custom.navbar-light .navbar-nav li.active a,
  .navbar-custom.navbar-light .navbar-nav li a:hover,
  .navbar-custom.navbar-light .navbar-nav li a:active {
    color: #343a40 !important;
  }
  .navbar-custom .call-no a {
    padding-left: 0 !important;
    color: #ff8057 !important;
  }
  .navbar-toggler {
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #343a40;
  }
  .navbar-light .nav-item .nav-link:after {
    background: #343a40;
  }
}
  .new-nav-ul{
    float: right;
    position: absolute;
    top: 0.2rem;
    right: 0;
  } */

/* .navbar.style-1 .navbar-collapse {
  display: flex!important;
  flex-basis: auto;
  position: absolute;
  right: 0;
} */

/* contact area */
.contact_info_item {
  border-bottom: 1px solid #eeebf6;
  padding-bottom: 25px;
  background-color: rgb(243, 247, 255);
}
.contact_info_item h1{
  margin-bottom: 2rem;
  padding: 1rem;
}
.contact_info_item p {
  color: #677294;
  margin-bottom: 8px;
  padding: 1rem;
  font-size: 16px;
}

.contact_info_item a {
  color: #677294;
  padding-left: 3px;
  display: inline-block;
}

.contact_info_item a:hover {
  color: #5e2ced;
}

.contact_info_item + .contact_info_item {
  border: 0px;
  margin-top: 33px;
}

/* .mapbox {
  height: 400px;
  -webkit-box-shadow: 0px 3px 4px 0px rgba(12, 0, 46, 0.05);
  box-shadow: 0px 3px 4px 0px rgba(12, 0, 46, 0.05);
  border: 10px solid #fff;
}

#mapBox {
  height: 100% !important;
} */

.text_box {
  margin-bottom: 20px;
}

.text_box input[type="text"], .text_box input[type="password"], .text_box input[type="email"] {
  font: 400 15px/60px "Poppins", sans-serif;
  color: #222d39;
  height: 60px;
  border-radius: 4px;
  background-color: inherit;
  /* -webkit-box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04); */
  /* box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04); */
  width: 100%;
  /* border: 1px solid #fff; */
  border: none;
  /* padding-left: 30px; */
}
.text_box textarea{
  color: #222d39;
  height: 60px;
  border-radius: 4px;
  background-color: inherit;
  width: 100%;
  border: none;
  padding-top: 1rem;
}

.text_box input[type="text"].placeholder, .text_box textarea.placeholder, .text_box input[type="password"].placeholder, .text_box input[type="email"].placeholder {
  color: #9ca3b9;
}

.text_box input[type="text"]:-moz-placeholder, .text_box textarea:-moz-placeholder, .text_box input[type="password"]:-moz-placeholder, .text_box input[type="email"]:-moz-placeholder {
  color: #9ca3b9;
}

.text_box input[type="text"]::-moz-placeholder, .text_box textarea::-moz-placeholder, .text_box input[type="password"]::-moz-placeholder, .text_box input[type="email"]::-moz-placeholder {
  color: #9ca3b9;
}

.text_box input[type="text"]::-webkit-input-placeholder, .text_box textarea::-webkit-input-placeholder, .text_box input[type="password"]::-webkit-input-placeholder, .text_box input[type="email"]::-webkit-input-placeholder {
  color: #9ca3b9;
}

.text_box input[type="text"]:focus, .text_box textarea:focus, .text_box input[type="password"]:focus, .text_box input[type="email"]:focus {
  border-color: #f2effc;
  /* -webkit-box-shadow: 0px 10px 14px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 10px 14px 0px rgba(12, 0, 46, 0.06); */
  outline: none;
}

.text_box textarea {
  height: 220px;
}
.contact_form{
  padding-left: 40px;
  /* margin: 0.1rem; */
}
.contact_form_box {
  overflow: hidden;
}
.contact_form_box .btn_three {
  min-width: 180px;
  text-align: center;
  background: linear-gradient(to right, #0c3df4 0%, #02b5ff 100%) !important;
  color: white;
  margin-top: 0px;
  border: none;
  float: right;
}


.mapbox2 {
  height: 440px;
  border-top: 10px solid #fff;
}
.text_box input{
  border-bottom: 1px solid #5482e6 !important;
}
.text_box textarea{
  border: 1px solid #f0f0f0 !important;
}
.contact-btm-con{
  margin-top: 1rem;
  display: flex;
  gap: 5rem;
}
.contact-btm-con button{
  height: 40px;
}
@media (max-width:700px) {
  .contact-btm-con{
    flex-direction: column;
    gap: 2rem;
  }}

 /* is poortfolio contact */




.portfolioC {
  border-bottom: 1px solid #eeebf6;
  /* max-width: 1400px; */
  /* margin: 0 auto; */
  background: linear-gradient(to right, #0c3df4 0%, #02b5ff 100%);
}
.portfolioC h1{
  color: white;
  margin-bottom: 2rem;
  padding: 1rem;
  padding-left: 0;
}
.portfolioC .contact_info_item{
  background-color: inherit;
}
.portfolioC p {
  color: #ffffff;
  margin-bottom: 8px;
  padding: 1rem;
  font-size: 16px;
  padding-left: 0;
}

.contact_info_item a {
  color: #677294;
  padding-left: 3px;
  display: inline-block;
}

.contact_info_item a:hover {
  color: #5e2ced;
}

.contact_info_item + .contact_info_item {
  border: 0px;
  margin-top: 33px;
}

.text_box {
  margin-bottom: 20px;
}

.portfolioC.text_box input[type="text"], .portfolioC.text_box textarea, .text_box select, .portfolioC.text_box input[type="password"], .portfolioC.text_box input[type="email"] {
  font: 400 15px/60px "Poppins", sans-serif;
  color: #222d39;
  height: 60px;
  border-radius: 4px;
  background-color: inherit;
  /* -webkit-box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04); */
  /* box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04); */
  width: 100%;
  border: 1px solid #fff;
  /* padding-left: 30px; */
}

.text_box input[type="text"].placeholder, .text_box textarea.placeholder, .text_box input[type="password"].placeholder, .text_box input[type="email"].placeholder {
  color: #9ca3b9;
}

.text_box input[type="text"]:-moz-placeholder, .text_box textarea:-moz-placeholder, .text_box input[type="password"]:-moz-placeholder, .text_box input[type="email"]:-moz-placeholder {
  color: #9ca3b9;
}

.text_box input[type="text"]::-moz-placeholder, .text_box textarea::-moz-placeholder, .text_box input[type="password"]::-moz-placeholder, .text_box input[type="email"]::-moz-placeholder {
  color: #9ca3b9;
}

.text_box input[type="text"]::-webkit-input-placeholder, .text_box textarea::-webkit-input-placeholder, .text_box input[type="password"]::-webkit-input-placeholder, .text_box input[type="email"]::-webkit-input-placeholder {
  color: #9ca3b9;
}

.text_box input[type="text"]:focus, .text_box textarea:focus, .text_box input[type="password"]:focus, .text_box input[type="email"]:focus, .text_box select {
  border-color: #f2effc;
  /* -webkit-box-shadow: 0px 10px 14px 0px rgba(12, 0, 46, 0.06);
  box-shadow: 0px 10px 14px 0px rgba(12, 0, 46, 0.06); */
  outline: none;
}

.text_box textarea {
  height: 220px;
}
.contact_form{
  padding-left: 40px;
  /* margin: 0.1rem; */
}
.contact_form_box {
  overflow: hidden;
}
.contact_form_box .btn_three {
  min-width: 180px;
  text-align: center;
  background: linear-gradient(to right, #0c3df4 0%, #02b5ff 100%) !important;
  color: white;
  margin-top: 0px;
  border: none;
  float: right;
  padding: 0.5rem;
}


.mapbox2 {
  height: 440px;
  border-top: 10px solid #fff;
}
.text_box input , select{
  border: none;
  border-bottom: 1px solid #5482e6 !important;
}
.text_box textarea{
  border: 1px solid #f0f0f0 !important;
}
.contact-btm-con{
  margin-top: 1rem;
  display: flex;
  gap: 5rem;
}
.contact-btm-con button{
  height: 40px;
}
@media (max-width:700px) {
  .contact-btm-con{
    flex-direction: column;
    gap: 2rem;
  }}



  .main_title h2 span::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40%;
    background-color: var(--color-blue5);
    opacity: 8%;
}
.main_title h2 span{
  /* text-transform: uppercase !important; */
  /* font-size: 16px; */
  /* font-weight: 500; */
  /* letter-spacing: 0.15rem; */
}
.main_title h2{    
  line-height: 2.3rem;
  font-weight: 600;
  text-transform: capitalize;
  /* font-size: 1.9rem; */
}
.why-us-title2>span::after{
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 40%;
  background-color: var(--color-blue5);
  opacity: 8%;
}
.test-status{
  padding-top: -1rem !important;
  color: rgba(0, 0, 0, 0.692);
}

.contact__info-steps {
  display: flex;
  flex-direction: column;
  max-width: 425x;
  row-gap: 20px;
  border-left: 1px solid #2e2e2e;
  border-left: 1px solid #97cbfc;
  box-sizing: border-box;
}
.contact__info-block {
  position: relative;
  padding-left: 45px;
}
.contact__info-text {
  margin: 0;
  /* font-size: 16px; */
  font-size: 1.125rem;
  line-height: 26px;
  color: #2E2E2E;
  color: rgba(255, 255, 255, 0.788);
  width: 100%;
}
.contact__info-step {
  position: absolute;
  border: 1px solid #2e2e2e;
  border: 1px solid #ffffff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-weight: 700;
  font-size: 18px;
  left: -20px;
  top: -8px;
  background-color: #fff;
  
}
.contact__info-step {
  background-color: #F5F5F7 !important;
  background: linear-gradient(to right, #0c3df4 0%, #02b5ff 100%) !important;
}
.contact__info {
  background-color: #F5F5F7 !important;
}
.contact__info {
  margin-right: 26px!important;
  margin-left: auto!important;
}
.contact__info {
  /* background-color: #f4f4f4; */
  background: linear-gradient(to right, #0c3df4 0%, #02b5ff 100%) !important;
  color: white !important;
  padding: 20px 44px 40px 50px;
  position: relative;
  max-width: 540px;
  /* font-family: 'Poppins' !important; */
  width: 100%;
}
.contact__info-background {
  z-index: -1;
  position: absolute;
  top: 20px;
  left: 20px;
  width: 100%;
  height: 100%;
  border: 1px dashed #4594ef;
}
.contact_info_area{
  padding: 3rem 0;
}
@media (max-width:991px) {
  .con_row_reverse{
    flex-direction: row-reverse;
  }
}

.shap_r_ap{
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  height: 400px;
}
.shap_l_ap{
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 1;
  height: 400px;
}
@media (max-width:700px) {
  .shap_l_ap{
    opacity: 0.5;
  }
  .shap_r_ap{
    opacity: 0.3;
  }
}

.home_page_contact .contact_form_box .text_box input{
  /* color:white !important; */
  background-color: rgb(255, 255, 255);
  padding-left: 1rem;
}
.home_page_contact .contact_form_box .text_box textarea{
  /* color:white !important; */
  padding-left: 1rem;
  background-color: rgb(255, 255, 255);
}
.home_page_contact .contact_form_box .text_box select{
  /* color:white !important; */
  padding-left: 1rem;
  background-color: rgb(255, 255, 255);
}
.home_page_contact .contact_form_box .black_btn{
  background-color: black !important;
  min-width: 180px;
  text-align: center;
  color: white;
  margin-top: 0px;
  border: none;
  float: right;
  padding: 0.5rem;
  color: white;
}