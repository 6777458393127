.about-container{
    max-width: 1400px;
}
.ourApproach-desc-container{
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}
.ourApproach-desc-container>h1{
  font-size: 3rem;
}
.ourApproach-desc-container>p{
  color: rgba(255, 255, 255, 0.685);
  font-size: 1.1rem;
  margin-top: 1.5rem;
}
.about-approach-img-container{
  
}

/* blog part */

.blog-container{
  padding: 0 5rem;
}
.blog-container>h1{
  text-align: center;
  margin-top: 8rem;
  margin-bottom: 5rem;
}
.blog-container>.blog-card-wrapper{
  display: flex;
  background-color: #fcfcfc17;
  padding: 1rem;
  gap: 3rem;
}
.blog-card-container>img{
  background-color: aqua;
  max-width: 500px;
  flex: 1;
  border-radius: 0.5rem;
}
.blog-card-container>.blog-desc-container{
  flex: 2;
}
.read-more-blog-btn{
  margin-top: 1.4rem;
  padding: 0.5rem 2rem;
  background-color: #1eb2a6;
  color:white;
  border: none;
  border-radius: 0.3rem;
}