.career-container{
    max-width: 1300px;
}
.why-join-wrapper{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 3rem;
    margin-top: 8rem;
    margin-bottom: 8rem;

}
.why-join-img-container{
    flex: 2;
}
.why-join-container{
    max-width: 800px;
    margin: 0 auto;
    padding: 0 1rem;
    flex: 3;
}
.why-join-container>h1{
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1.4rem;
}
.why-join-container>p{
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.781);
    line-height: 1.4rem;
    margin-bottom: 1rem;
    text-align: start;
}
.why-join-container>button{
    padding: 0.5rem 2rem;
    background-color: #1eb2a6;
    color:white;
    border: none;
    border-radius: 0.3rem;
}
.why-work-container{
    display: flex;
    gap: 3rem;
    margin-top: 5rem;
}
.whywork-quality-card-container{
    flex: 1;
}
.why-work-desc{
    flex: 1;
}
.why-work-desc>h1{
    font-size: 3rem;
    margin-bottom: 1.4rem;
}
.why-work-desc>p{
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.548);
}